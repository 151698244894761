<template>
	<div>
		<Table 
    :table="table"
     @fetch-data="fetchData" 
     @delete="deleteUser" 
     @search-data="initialize"
     @edit="editUser"
     />
	</div>
</template>

<script>
import Table from "@/components/core/Table"
import network from '@/core/network'
export default {
	components: {
		Table
	},
	data() {
		return {
			table: {
				page: 1,
				itemsPerPage: 10,
				totalItems: 0,
				desserts: [],
        search: '',
				headers: [
					{
						text: 'Số hộ chiếu',
						value: 'visa_number',
						sortable: false
					},
          {
						text: 'Tên',
						value: 'name',
						sortable: false
					},
          {
						text: 'Ngày sinh',
						value: 'date_of_birth',
						sortable: false
					},
					{
						text: 'Ngày đăng kí xin visa',
						value: 'register_date',
						sortable: false
					},
          // {
          //   text: 'Tư cách lưu trú',
          //   value: 'status_of_residence',
          //   sortable: false
          // },
          // {
          //   text: 'Trạng thái tiến trình',
          //   value: 'process_status',
          //   sortable: false
          // },
          // {
          //   text: 'Doanh nghiệp bên Hàn',
          //   value: 'business_in_korea',
          //   sortable: false
          // },
          // {
          //   text: 'Người được mời',
          //   value: 'invited_person',
          //   sortable: false
          // },
          // {
          //   text: 'Ngày xét duyệt',
          //   value: 'approved_date',
          //   sortable: false
          // },
          // {
          //   text: 'Lý do nhập cảnh',
          //   value: 'reason_for_entry',
          //   sortable: false
          // },
          
          {
            text: 'Tạo lúc',
            value: 'created_at',
            sortable: false
          },
       
					{ text: 'Thao tác', value: 'actions', sortable: false, width: "100px" },
				],
			}
		}
	},
	mounted () {
    this.initialize();
  },
	methods: {
		fetchData(page) {
      this.page = page;
			this.initialize()
		},
		initialize (searchData) {
      this.search = searchData
      network.get('/userInfo/all',{
        search: searchData ?? '',
        page: this.table.page,
        limit: 10
      }).then(res => {
        console.log(res);
        this.table.desserts = res.data.docs
        this.table.totalItems = res.data.totalDocs
        console.log(this.desserts);
      })
    },
    editUser(id) {
    this.$router.push(`/admin/user/${id}`)
    },
    deleteUser(id) {
      network.post('/userInfo/delete', 
      {id: id}
      ).then(res => {
        console.log(res);
        if (res.status == 200) {
          this.$toast.open({
            message: res.data.message,
            type:'success',
            position: 'top'
          });
          this.initialize();
        } else {
          this.$toast.open({
            message: res.data.message,
            type: 'error',
            position: 'top'
          });
        }
      }).catch(err => {
        this.$toast.open({
          message: err,
          type: 'error',
          position: 'top'
      });
    });
	}
}

}
</script>

<style></style>